import * as React from "react"
import { Link } from "gatsby"
import Bio from "../components/bio"
import Footer from "./home-footer"
import { Paragraph } from "./typography"

const PostFooter = ({ previous, next }) => (
  <Footer
  >
    {(previous || next) && (
      <ul
      className="w-full pt-1 flex flex-col justify-center"
      >
        <li className="flex justify-center">
          {previous && (

            <Link as={Link} to={previous.slug} rel="prev">
              ← {previous.title}
            </Link>
          )}
        </li>
        <li className="flex justify-center">
          {next && (
            <Link  to={next.slug} rel="next">
              {next.title} →
            </Link>
          )}
        </li>
      </ul>
    )}
  </Footer>
)

export default PostFooter
