import * as React from "react"
import { getSrc } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "./layout"
import SEO from "./seo"
import PostTitle from "./post-title"
import PostDate from "./post-date"
import PostFooter from "./post-footer"
import PostHero from "./post-hero"
import { Grid } from "./grid"
import { H2, H6, Paragraph } from './typography'
import { ArrowLink, BackLink } from './arrow-button'
import { formatDate } from "../utils/misc"
const Post = ({
  data: {
    post,
    site: {
      siteMetadata: { title },
    },
  },
  location,
  previous,
  next,
}) => (
  <Layout location={location} title={title}>
    <SEO
      title={post.title}
      description={post.excerpt}
      imageSource={
        post.socialImage ? getSrc(post.socialImage) : getSrc(post.image)
      }
      imageAlt={post.imageAlt}
    />
    <div
      key={post.title}

    >
      <Grid className="mb-3 mt-4">
        <div className="col-span-full flex justify-between lg:col-span-8 lg:col-start-3">
          <BackLink to="/">Back</BackLink>
        </div>
      </Grid>
    </div>
    <main>
      <article>
        <Grid as="header" className="mb-12">
          {/* <PostHero post={post} /> */}
          <div className="col-span-full lg:col-span-8 lg:col-start-3">
            {post.isDraft ? (
              <div className="prose prose-light mb-6 max-w-full dark:prose-dark">
                {React.createElement(
                  'callout-warning',
                  {},
                  `This blog post is a draft. Please don't share it in its current state.`,
                )}
              </div>
            ) : null}
            <PostTitle>{post.title}</PostTitle>
            <H6 as="p" variant="secondary" className="mt-2 mb-2">
              {post.date
                ? formatDate(post.date)
                : 'some day in the past'}{' '}
              {/* — {data.page.readTime?.text ?? 'a quick read'} */}
            </H6>
            <PostHero post={post} />
          </div>
        </Grid>
        <Grid as="main" className="prose prose-light mb-24 dark:prose-dark">
          <MDXRenderer >{post.body}</MDXRenderer>
        </Grid>
        {/* <header>
          <PostHero post={post} />
          <PostTitle>{post.title}</PostTitle>
          <PostDate>{post.date}</PostDate>
        </header> */}
        {/* <section>
          <MDXRenderer>{post.body}</MDXRenderer>
        </section> */}
      </article>
    </main>
    <PostFooter {...{ previous, next }} />
  </Layout>
)

const PostWrapper = ({ location, data }) => {
  const { blogPost, previous, next } = data

  return (
    <Post
      data={{ ...data, post: blogPost }}
      location={location}
      previous={previous}
      next={next}
    />
  )
}
export default PostWrapper
